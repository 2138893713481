export default [
    {
        path: '/en/projects',
        name: 'projects',
    },
    {
        path: '/en/publications',
        name: 'publications',
    },
    {
        path: '/en/news',
        name: 'news',
    },
    {
        path: '/en/contact',
        name: 'contact',
    },
]